import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'
import clsx from 'clsx'
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'
import { isLengthEq, isPresent, isSame } from 'lib/utils'

import Image from 'next/image'
import { Fragment, useEffect, useMemo, useState } from 'react'
import NextButton from './NextButton'
import PrevButton from './PrevButton'

export type ImageModalProps = {
  src: StaticImageData | string
  images?: StaticImageData[]
  idx?: number
  isOpen: boolean
  setIsOpen: (...args: any) => void
  maxWidthClass?: string
}

let ImageModalDsk = ({
  src,
  images,
  idx,
  isOpen,
  setIsOpen,
  maxWidthClass = '',
}: ImageModalProps) => {
  let [imageIdx, setImageIdx] = useState(idx)

  let isSlider = useMemo(
    () => isPresent(images) && isPresent(idx),
    [idx, images]
  )

  useIsomorphicLayoutEffect(() => {
    if (!isSlider) {
      return
    }

    isOpen && setImageIdx(idx)
    let timeout: NodeJS.Timeout
    if (!isOpen) {
      timeout = setTimeout(() => setImageIdx(idx), 300)
    }

    return () => {
      isPresent(timeout) && clearTimeout(timeout)
    }
  }, [idx, isOpen, isSlider])

  useEffect(() => {
    let handleKeyPress = ({ key }: any) => {
      if (!isSlider) {
        return
      }

      if (isSame(key, 'ArrowLeft')) {
        setImageIdx((prev) =>
          isSame(prev, 0) ? images!.length - 1 : prev! - 1
        )
      }

      if (isSame(key, 'ArrowRight')) {
        setImageIdx((prev) =>
          isLengthEq(imageIdx! + 1, images) ? 0 : prev! + 1
        )
      }
    }

    window.addEventListener('keydown', handleKeyPress)

    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [idx, imageIdx, images, isSlider])

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as='div'
        className='fixed z-60 inset-0'
        onClose={() => setIsOpen(false)}
      >
        <div className='min-h-screen flex items-center justify-center'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-dark bg-opacity-75 transition-opacity' />
          </Transition.Child>

          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div
              className={clsx(
                'transform transition-all w-4/5 h-screen p-5',
                maxWidthClass
              )}
            >
              <div className='relative flex items-start'>
                <button
                  aria-label='Закрыть'
                  type='button'
                  className='absolute -top-8 right-0 md:top-0 md:-right-8 focus:outline-none z-10'
                  onClick={() => setIsOpen(false)}
                >
                  <XIcon
                    className='h-6 w-6 text-white'
                    aria-hidden='true'
                  />
                </button>
              </div>
              <div className='h-full relative'>
                <Image
                  src={isSlider ? images![imageIdx!]! : src}
                  alt=''
                  layout='fill'
                  objectFit='contain'
                />

                {isSlider && (
                  <>
                    <div className='absolute left-[-10%] top-1/2 -translate-y-1/2'>
                      <PrevButton
                        onClick={() =>
                          setImageIdx((prev) =>
                            isSame(prev, 0)
                              ? images!.length - 1
                              : prev! - 1
                          )
                        }
                      />
                    </div>

                    <div className='absolute right-[-10%] top-1/2 -translate-y-1/2'>
                      <NextButton
                        onClick={() =>
                          setImageIdx((prev) =>
                            isLengthEq(imageIdx! + 1, images)
                              ? 0
                              : prev! + 1
                          )
                        }
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ImageModalDsk
