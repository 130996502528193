import { isNonEmptyString, isPresent } from 'lib/utils'
import { IProduct } from 'store/api'

type Props = {
  product: IProduct
  fullDesc?: string
  weight: string | number | undefined
  kcal?: string
  proteins?: string
  fats?: string
  hydrocarbonates?: string
}

const ProductInfo = ({
  product,
  fullDesc,
  weight,
  kcal,
  proteins,
  fats,
  hydrocarbonates,
}: Props) => {
  return (
    <div className='mb-4'>
      <h1
        itemProp='name'
        className='font-medium font-unbounded text-xl lg:text-5xl mb-4'
      >
        {product.name}
      </h1>

      {isNonEmptyString(product.short_desc?.trim()) && (
        <p className='font-light text-base2 text-dark leading-tight mb-2.5'>
          {product.short_desc}
        </p>
      )}
      {isPresent(fullDesc) && isNonEmptyString(fullDesc) && (
        <div itemProp='description' className='my-4'>
          <span className='font-semibold'>Состав: </span>
          <div
            className='font-light text-base2 text-dark leading-tight'
            dangerouslySetInnerHTML={{ __html: fullDesc }}
          ></div>
        </div>
      )}
      {isNonEmptyString(weight) && (
        <p
          itemProp='weight'
          className='font-light text-base2 text-dark leading-tight mb-2.5'
        >
          <span className='font-semibold'>Вес: </span>
          {`${weight} г`}
        </p>
      )}
      {isNonEmptyString(kcal) && (
        <p className='font-light text-base2 text-dark leading-tight'>
          <span className='font-semibold'>На 100 г: </span>
          {`Ккал: ${kcal}, Б: ${proteins} г, Ж: ${fats} г, У: ${hydrocarbonates} г`}
        </p>
      )}
    </div>
  )
}

export default ProductInfo
