import clsx from 'clsx'
import ImageModalDsk from 'components/common/ImageModalDsk'
import Img from 'components/common/Img'
import { MEDIA_URL } from 'config/constants'
import { isNonEmptyArray, isPresent } from 'lib/utils'
import dynamic from 'next/dynamic'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { IProduct } from 'store/api'
import { selectIsLg } from 'store/ui'
import { IProductDetail } from './types'

const ImageSlider = dynamic(
  () => import('components/common/ImageSlider')
)

type Props = {
  product: IProduct
  productDetail: IProductDetail
  classNames: string
  isPage?: boolean
}

const ProductImages = ({
  productDetail,
  product,
  classNames,
  isPage = false,
}: Props) => {
  let isLg = useSelector(selectIsLg)
  let [isDskModalOpen, setIsDskModalOpen] = useState(false)

  if (
    isPresent(productDetail) && isPresent(productDetail?.product) && Object.prototype.hasOwnProperty.call(
      productDetail.product,
      'additional_images'
    ) &&
    isNonEmptyArray(productDetail.product.additional_images)
  )
    return (
      <ImageSlider
        images={[
          `${MEDIA_URL}${product.image}`,
          ...productDetail.product.additional_images.map(
            (i: string) => `${MEDIA_URL}${i}`
          ),
        ]}
        aspectRatio='aspect-w-1 aspect-h-1'
        isPage={isPage}
      />
    )

  return (
    <>
      <div className='mx-auto max-w-[600px] max-h-[600px] w-full'>
        <div
          itemProp='image'
          className={clsx('relative', classNames, {
            'cursor-zoom-in': isPage && isLg,
          })}
        >
          <Img
            src={`${MEDIA_URL}${product.image}`}
            alt={product.meta.img_alt}
            title={product.meta.img_title}
            width='100%'
            height='100%'
            layout='responsive'
            objectFit='cover'
            onClick={() =>
              isLg && isPage ? setIsDskModalOpen(true) : undefined
            }
          />
        </div>
      </div>
      {isLg && isPage && (
        <ImageModalDsk
          idx={0}
          src={`${MEDIA_URL}${product.image}`}
          isOpen={isDskModalOpen}
          setIsOpen={setIsDskModalOpen}
          maxWidthClass='max-w-[600px]'
        />
      )}
    </>
  )
}

export default ProductImages
