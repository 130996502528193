import {
  isNonEmptyArray,
  isNonEmptyObj,
  isPresent,
  sum,
} from 'lib/utils'
import { useCallback, useMemo, useState } from 'react'
import {
  IProductIngGroups,
  IProductIngInfo,
  IProductIngredient,
} from './types'
const useIngredients = (
  options: {
    ingsgroups?: IProductIngGroups[]
  },
  groupsingsProps: {
    [name: number]: IProductIngredient
  },
  productQty: number
) => {
  const [selectedIngs, setSelectedIngs] = useState<
    [IProductIngInfo, number][]
  >([])
  const multiplicity = useMemo(
    () =>
      isNonEmptyArray(options.ingsgroups)
        ? +options.ingsgroups[0].additional
            .ingredients_multiplicity ?? 0
        : 0,
    [options]
  )
  const maxQty = useMemo(
    () =>
      isNonEmptyArray(options.ingsgroups)
        ? options.ingsgroups[0].additional.ingredients_max_qty ?? 0
        : 0,
    [options]
  )
  const minQty = useMemo(
    () =>
      isNonEmptyArray(options.ingsgroups)
        ? options.ingsgroups[0].additional?.ingredients_min_qty ?? 0
        : 0,
    [options]
  )
  const ingTotalCount = useMemo(
    () => sum(selectedIngs.map((i) => i[1])),
    [selectedIngs]
  )
  const ingTotalSum = useMemo(
    () =>
      sum(
        selectedIngs.map(
          ([ing, quantity]) =>
            groupsingsProps[ing.ingredientId]!.meta[
              ing.ingredientVariationId
            ]!.price *
            quantity *
            productQty
        )
      ),
    [selectedIngs, productQty, groupsingsProps]
  )
  const handleToggleIng = useCallback(
    (ing: IProductIngInfo, quantity: number) => {
      setSelectedIngs((prev) => {
        if (ingTotalCount == maxQty && maxQty !== 0 && quantity > 0) {
          return prev
        } else {
          let existedIng = prev.find(
            (i) => i[0].ingredientId === ing.ingredientId
          )
          if (isPresent(existedIng)) {
            if (
              quantity == 0 ||
              (existedIng[1] == 1 && quantity == -1)
            )
              return prev.filter(
                (i) => i[0].ingredientId !== ing.ingredientId
              )
            else {
              existedIng[1] += quantity
              return prev.slice()
            }
          } else return [...prev, [ing, 1]]
        }
      })
    },
    [maxQty, ingTotalCount]
  )

  const ingredientsList = useMemo(() => {
    let filtered: IProductIngInfo[] = []
    const ingsgroups = options.ingsgroups
    if (
      isPresent(groupsingsProps) &&
      isNonEmptyObj(groupsingsProps) &&
      isPresent(ingsgroups) &&
      isNonEmptyArray(ingsgroups)
    ) {
      filtered = ingsgroups[0].ingredients.filter(
        (ing: IProductIngInfo) => {
          return (
            isPresent(groupsingsProps[ing.ingredientId]?.meta) &&
            isPresent(
              groupsingsProps[ing.ingredientId]?.meta[
                ing.ingredientVariationId
              ]
            )
          )
        }
      )
    }
    return filtered
  }, [groupsingsProps, options])

  return {
    selectedIngs,
    handleToggleIng,
    multiplicity,
    maxQty,
    minQty,
    ingTotalCount,
    ingTotalSum,
    ingredientsList,
  }
}
export default useIngredients
