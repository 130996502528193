import { isNonEmptyObj, isPresent } from 'lib/utils'
import { useCallback, useMemo, useState } from 'react'
import { IProductDetail } from './types'

const useVariations = (
  productDetail: IProductDetail | undefined,
  productType: 'simple' | 'variable',
  id: number,
  price: number,
  weight: string,
) => {
  const [variationId, setVariationId] = useState(-1)
  const onVariationChanged = useCallback((id: number) => {
    setVariationId(id)
  }, [])
  const isVariationSelected = useMemo(
    () =>
      isPresent(productDetail) &&
      isNonEmptyObj(productDetail?.productsProps) &&
      variationId > 0,
    [productDetail, variationId]
  )
  const variationInfo = useMemo(
    () =>
      isVariationSelected
        ? productDetail?.productsProps[id]?.meta[variationId]!
            .additional
        : null,
    [isVariationSelected, productDetail, id, variationId]
  )
  const nutritionValue = useMemo(
    () =>
      isVariationSelected ? variationInfo : productDetail?.product,
    [isVariationSelected, variationInfo, productDetail]
  )
  const variationPrice = useMemo(() => isVariationSelected ? variationInfo?.price : price, [isVariationSelected, variationInfo?.price, price])
  
  const variationWeight = useMemo(
    () => (isVariationSelected ? variationInfo?.weight : weight),
    [isVariationSelected, variationInfo?.weight, weight]
  )
  const showVariations = useMemo(
    () =>
      productType === 'variable' &&
      isPresent(productDetail) &&
      isPresent(productDetail.productsProps[id]),
    [productType, productDetail, id]
  )
  return {
    variationId,
    onVariationChanged,
    variationPrice,
    variationWeight,
    showVariations,
    nutritionValue: nutritionValue ?? {},
  }
}
export default useVariations
